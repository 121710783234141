//--------------------------------------------------------
// トップ/動物園リスト　コンポーネント
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
const regions = [
  { id: 'hokkaidou', label: '北海道', icon: '/img/icon-hokkaidou.png' },
  { id: 'touhoku', label: '東北', icon: '/img/icon-touhoku.png' },
  { id: 'kantou', label: '関東', icon: '/img/icon-kantou.png' },
  { id: 'chubu', label: '中部', icon: '/img/icon-chubu.png' },
  { id: 'kinki', label: '近畿', icon: '/img/icon-kinki.png' },
  { id: 'chugoku', label: '中国', icon: '/img/icon-chugoku.png' },
  { id: 'shikoku', label: '四国', icon: '/img/icon-shikoku.png' },
  { id: 'kyushu', label: '九州', icon: '/img/icon-kyushu.png' },
  { id: 'okinawa', label: '沖縄', icon: '/img/icon-okinawa.png' },
];

const ZooList = () => {
  return (
    <section id="zooList">
      <h2>日本国内の動物園</h2>
      <ul>
        {regions.map((region) => (
          <li key={region.id}>
            <a href={`list.html#${region.id}`}>
              <img src={region.icon} alt={region.label} loading="lazy" />
            </a>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default ZooList;
