//--------------------------------------------------------
// ヘルパー
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
import React from 'react';

//--------------------------------------------------------
// 改行コードを <br /> に変換する関数
//--------------------------------------------------------
export function convertNewlinesToBr(text) {
  if (!text) return null;

  return text.split(/\r\n|\n|\r/).map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index < text.split(/\r\n|\n|\r/).length - 1 && <br />}
    </React.Fragment>
  ));
}

//--------------------------------------------------------
// テキストに含まれるaタグを解釈する関数
//--------------------------------------------------------
export function parseLinks(element) {
  if (!element) return null;

  // 要素が文字列の場合はそのまま返す
  if (typeof element === "string") return element;

  // 子要素を再帰的に解析
  return React.Children.map(element, (child) => {
    if (typeof child === "string") {
      // <a>タグを検出する正規表現
      const linkRegex = /<a href="([^"]+)">([^<]+)<\/a>/g;
      const parts = [];
      let lastIndex = 0;
      let match;

      while ((match = linkRegex.exec(child)) !== null) {
        // 前のテキスト部分
        if (match.index > lastIndex) {
          parts.push(child.slice(lastIndex, match.index));
        }

        // <a>タグ部分
        parts.push(
          <a
            key={parts.length}
            href={match[1]}
            target="_blank"
            rel="noopener noreferrer"
          >
            {match[2]}
          </a>
        );

        lastIndex = match.index + match[0].length;
      }

      // 残りのテキスト部分
      if (lastIndex < child.length) {
        parts.push(child.slice(lastIndex));
      }

      return parts;
    }

    // 再帰的に処理
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        children: parseLinks(child.props.children),
      });
    }

    return child;
  });
}

//--------------------------------------------------------
// 特定のプレフィックスで始まるキーの値が空かどうかを判定する関数
//--------------------------------------------------------
export function hasDataWithPrefix(data, prefix) {
    return Object.keys(data).some(key => key.startsWith(prefix) && Boolean(data[key]));
};

//--------------------------------------------------------
// ナイトサファリ表示・非表示月
//--------------------------------------------------------
export const shouldDisplayNightSafari = () => {
  const currentMonth = new Date().getMonth() + 1; //現在月
  return currentMonth >= 7 && currentMonth <= 10; //7月~10月ならtrue
};

