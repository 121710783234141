//--------------------------------------------------------
// Reactの外部から#リンクでアクセスした時のため
// Create 2024/11 PANDA DOBASHI
// Update 2025/02 動作修正
//--------------------------------------------------------
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToHash = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const scrollToHash = () => {
        setTimeout(() => {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }, 300); // 300ms 遅延させて確実に実行
      };

      // 初回ロード時も確実に実行
      if (document.readyState === "complete") {
        scrollToHash();
      } else {
        window.addEventListener("load", scrollToHash);
      }

      return () => {
        window.removeEventListener("load", scrollToHash);
      };
    }
  }, [hash]);
};

export default ScrollToHash;
