//--------------------------------------------------------
// ナイトサファリ
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
import { convertNewlinesToBr, hasDataWithPrefix, shouldDisplayNightSafari } from '../../utils/helpers';

const NightSafari = ({ zooData }) => {
  //表示期間外
  if (shouldDisplayNightSafari() === false) {
    return null;
  }

  const {
    a_ns_july,
    a_ns_august,
    a_ns_september,
    a_ns_october,
    a_ns_opening_hours,
    a_ns_additional_fee,
    a_ns_detail_url,
    a_ns_notes,
    b_ns_name,
    b_ns_july,
    b_ns_august,
    b_ns_september,
    b_ns_opening_hours,
    b_ns_target_audience,
    b_ns_notes,
    b_ns_detail_url,
    zoo_name,
  } = zooData;

  // a_ns_の値が存在するか (通常のナイトサファリ)
  const hasANs = hasDataWithPrefix(zooData, 'a_ns_');

  // b_ns_の値が存在するか (障害者向けのナイトサファリ)
  const hasBNs = hasDataWithPrefix(zooData, 'b_ns_');

  if (!hasANs && !hasBNs) {
    return null; // どちらもない場合は何も表示しない
  }

  return (
    <div id="nightsafari">
      {/* ナイトサファリ情報 */}
      {hasANs && (
        <section id="night">
          <h2>
            <img src="/img/icon-night2.svg" alt=""/>ナイトサファリ情報
          </h2>
          <div className="contentsBody">
            <div className="kihon-joho">
              <dl>
                {(a_ns_july || a_ns_august || a_ns_september || a_ns_october) && (
                    <>
                    <dt><img src="/img/icon-calendar.svg" alt=""/>開催日</dt>
                    <dd>
                    {a_ns_july && <>7月：{a_ns_july}<br /></>}
                    {a_ns_august && <>8月：{a_ns_august}<br /></>}
                    {a_ns_september && <>9月：{a_ns_september}<br /></>}
                    {a_ns_october && <>10月：{a_ns_october}<br /></>}
                    </dd>
                    </>
                )}
                {a_ns_opening_hours && (
                    <>
                    <dt><img src="/img/icon-time.svg" alt=""/>開園時間</dt>
                    <dd>{convertNewlinesToBr(a_ns_opening_hours)}</dd>
                    </>
                )}
                {a_ns_additional_fee && (
                  <>
                    <dt><img src="/img/icon-money.svg" alt=""/>別途料金</dt>
                    <dd>{a_ns_additional_fee}</dd>
                  </>
                )}
                {a_ns_notes && (
                  <>
                    <dt><img src="/img/icon-note.svg" alt=""/>備考</dt>
                    <dd>{convertNewlinesToBr(a_ns_notes)}</dd>
                  </>
                )}
                {a_ns_detail_url && (
                  <>
                    <dt><img src="/img/icon-detail.svg" alt=""/>詳細</dt>
                    <dd>
                      公式サイトへ <a href={a_ns_detail_url} target="_blank" rel="noopener noreferrer">{zoo_name}</a>
                    </dd>
                  </>
                )}
              </dl>
            </div>
          </div>
        </section>
      )}

      {/* ドリームナイト・アット・ザ・ズー情報 */}
      {hasBNs && (
        <section id="dream">
          <h2>
            <img src="/img/icon-night2.svg" alt=""/>{b_ns_name || 'ドリームナイト・アット・ザ・ズー'}情報
          </h2>
          <div className="contentsBody">
            <div className="kihon-joho">
              <dl>
              {b_ns_target_audience && (
                  <>
                    <dt><img src="/img/icon-subject.svg" alt=""/>対象</dt>
                    <dd>{b_ns_target_audience}</dd>
                  </>
                )}
                {(b_ns_july || b_ns_august || b_ns_september) && (
                  <>
                    <dt><img src="/img/icon-calendar.svg" alt=""/>開催日</dt>
                    <dd>
                      {b_ns_july && <>7月：{b_ns_july}<br /></>}
                      {b_ns_august && <>8月：{b_ns_august}<br /></>}
                      {b_ns_september && <>9月：{b_ns_september}<br /></>}
                    </dd>
                  </>
                )}
                {b_ns_opening_hours && (
                  <>
                    <dt><img src="/img/icon-time.svg" alt=""/>開園時間</dt>
                    <dd>{convertNewlinesToBr(b_ns_opening_hours)}</dd>
                  </>
                )}
                {b_ns_notes && (
                  <>
                    <dt><img src="/img/icon-note.svg" alt=""/>備考</dt>
                    <dd>{convertNewlinesToBr(b_ns_notes)}</dd>
                  </>
                )}
                {b_ns_detail_url && (
                  <>
                    <dt><img src="/img/icon-detail.svg" alt=""/>詳細</dt>
                    <dd>
                      公式サイトへ <a href={b_ns_detail_url} target="_blank" rel="noopener noreferrer">{zoo_name}</a>
                    </dd>
                  </>
                )}
              </dl>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default NightSafari;
