//--------------------------------------------------------
// バナー　コンポーネント
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------

const Banner = () => {    
    return (
        <div id="panda-koubou">
            <a href="https://www.panda-panda.com"><img src="/img/banner03.png" width="750" height="390" alt=""/></a>
        </div>
    );
};

export default Banner;