//--------------------------------------------------------
// 動物園情報
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import ExtraInfo from '../components/Zoo/ExtraInfo';
import Highlights from '../components/Zoo/Highlights';
import Navigation from '../components/Zoo/Navigation';
import BasicInfo from '../components/Zoo/BasicInfo';
import NewsArea from '../components/Zoo/NewsArea';
import AccessMap from '../components/Zoo/AccessMap';
import FunArea from '../components/Zoo/FunArea';
import NightSafari from '../components/Zoo/NightSafari';
import NearZoos from '../components/Zoo/NearZoos';
import Pickup from '../components/Pickup';
import Banner from '../components/Banner';

const Zoo = () => {
    const { zooId } = useParams(); // URLからzooIdを取得
    const [loading, setLoading] = useState(true);  // ローディング状態
    const [error, setError]     = useState(false);  // エラー状態
    const [zooData, setZooData] = useState(null); //動物園情報
    const [anniversaryContent, setAnniversaryContent] = useState(''); //周年特集
    const [showAnniversary, setShowAnniversary] = useState(false); //周年特集の表示or非表示

    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        if (zooId) {
            // APIから特定の動物園情報を取得
            axios.get(`${apiUrl}/api/zoo/${zooId}`)
            .then(response => {
                if (response.data) {
                    setZooData(response.data);

                    // 周年特集のtmplファイルをチェック
                    axios.get(`${apiUrl}/api/anniversary/${zooId}`)
                    .then(fileResponse => {
                    if (fileResponse.status === 200) {
                        setShowAnniversary(true);
                        setAnniversaryContent(fileResponse.data); // tmplの内容を保存
                    }
                    })
                    .catch(() => setShowAnniversary(false)); // ファイルが存在しない場合
                } else {
                    setError(true); // データが見つからない場合もエラー扱い
                }
                setLoading(false);
            })
            .catch(err => {
                setError(true);
                setLoading(false);
            });
        } else {
            setError(true);
            setLoading(false);
        }
    }, [zooId, apiUrl]);

    // エラーが発生した場合に404ページにリダイレクト
    useEffect(() => {
        if (error) {
            window.location.href = '/404.html';
        }
    }, [error]);

    // ローディング中
    if (loading) {
        return <p className="loading"><img src="/img/loading.svg" alt="読み込み中" width="200" height="200"/></p>;
    }

    if (!zooData) {
        return null;
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>{zooData.zoo_name} │ 動物園に行こう!!</title>
                <link rel="stylesheet" href="/css/zoo.css?v=20241122" />
            </Helmet>
            <main role="main">
                <h1>{zooData.zoo_name}</h1>
                {/* 臨時情報 */}
                <ExtraInfo extraInfo={zooData.extra_info}/>
                {/* 見どころ */}
                <Highlights highlights={zooData.highlights} />
                {/* ページナビゲーション */}
                <Navigation zooData={zooData} />
                {/* 基本情報 */}
                <BasicInfo zooData={zooData} />
                <div id="bgStyle1">
                    {/* 周年特集 */}
                    {showAnniversary && (
                        <div dangerouslySetInnerHTML={{ __html: anniversaryContent }} />
                    )}
                    {/* ニュース */}
                    <NewsArea zooId={zooId} />
                    {/* アクセス・地図 */}
                    <AccessMap zooData={zooData} />
                    {/* もっと楽しむ */}
                    <FunArea fanClub={zooData.fan_club} />
                    {/* ナイトサファリ */}
                    <NightSafari zooData={zooData} />
                    {/* 近くの動物園 */}
                    <NearZoos nearbyZoos={zooData.nearby_zoos_data} />
                    {/* いま人気の特集ページ */}
                    <Pickup />
                </div>
                <div className="btnContact"><a href="/contact/"><img src="/img/btn-contact.png" alt=""/>掲載情報の訂正<br />お問い合わせ</a></div>
                <Banner />
            </main>
        </HelmetProvider>
    );
};

export default Zoo;
