import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Top from './pages/Top';
import ContactForm from './pages/ContactForm';
import NewsList from './pages/NewsList';
import NewsArticle from './pages/NewsArticle';
import Zoo from './pages/Zoo';
import Header from './components/Header';
import Navi from './components/Navi';
import Footer from './components/Footer';
import ScrollToHash from './components/ScrollToHash';

function NotFoundRedirect() {
  React.useEffect(() => {
    // 404.htmlにリダイレクト
    if (window.location.pathname !== '/404.html') {
      window.location.href = '/404.html';
    }
  }, []);
  return null;
}

function App() {
  return (
    <Router>
        <ScrollToHash />
        <Header />
        <Navi />
        {/* URLに基づいてコンポーネントを切り替える */}
        <Routes>
          {/* トップページ */}
          <Route path="/" element={<Top />} />
          {/* 問い合わせ */}
          <Route path="/contact/" element={<ContactForm />} />
          {/* ニュース一覧 */}
          <Route path="/news/" element={<NewsList />} />
          {/* ニュース詳細 */}
          <Route path="/news/post.html" element={<NewsArticle />} />
          {/* 動物園情報 */}
          <Route path="/zoo/:zooId.html" element={<Zoo />} />
          {/* 存在しないページ */}
          <Route path="*" element={<NotFoundRedirect />} />
        </Routes>
        <Footer />
    </Router>
  );
}

export default App;
