//--------------------------------------------------------
// ニュース　一覧
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSearchParams, Link } from 'react-router-dom';
import Banner from '../components/Banner';

const NewsList = () => {
    const [news, setNews]           = useState([]); // ニュースデータ
    const [prevUrl, setPrevUrl]     = useState(''); // 前のページのURL
    const [nextUrl, setNextUrl]     = useState(''); // 次のページのURL
    const [loading, setLoading]     = useState(true); // ローディング状態
    const [error, setError]         = useState(null); // エラー状態
    const [title, setTitle]         = useState('ニュース一覧〜動物園に行こう!!'); //デフォルトタイトル
    const [headline, setHeadline]   = useState('ニュース'); //デフォルト題名
    
    // クエリパラメータを取得
    const [searchParams] = useSearchParams();
    const page = searchParams.get('paged') || 1; // デフォルトで1ページ目
    const zooId = searchParams.get('zoo_id') || null; // zoo_idがない場合はnull

    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    // コンポーネントがマウントされたときにAPIからデータを取得
    useEffect(() => {
        // パラメータを動的に構築
        const params = {
            paged: page,
        };
        if (zooId !== null) {
            params.zoo_id = zooId;
        }

        axios.get(`${apiUrl}/api/news-list`, { params })  // CodeIgniter API
        .then(response => {
            const data = response.data;
            setNews(data.items);  // ニュースデータ
            setPrevUrl(data.prev_url);   // 前のページURL
            setNextUrl(data.next_url);   // 次のページURL
            // タイトルを更新
            if (data.title) {
                setTitle(`${data.title}のニュース一覧〜動物園に行こう!!`);
                setHeadline(`${data.title}のニュース`);
            }

            setLoading(false);  // ローディング終了
        })
        .catch(err => {
            setError('ニュースの取得に失敗しました');
            setLoading(false);  // エラーが発生した場合もローディングを終了
        });
    }, [page, zooId, apiUrl]);

    // エラーが発生した場合
    if (error) {
        return (
            <main role="main" id="news-list">
                <p>エラーが発生しました: {error}</p>
            </main>
        );
    }

    // ローディング中
    if (loading) {
        return <p className="loading"><img src="/img/loading.svg" alt="読み込み中" width="200" height="200"/></p>;
    }

    // ニュースデータが空の場合
    if (!loading && news.length === 0) {
        return (
            <main role="main" id="news-list">
                <p>ニュースがありません。</p>
            </main>
        );
    }

    // ニュースデータが存在する場合にリストを表示
    return (
        <HelmetProvider>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content="動物園のニュースを紹介しています。" />
                <link rel="stylesheet" href="/css/news.css?v=20241030" />
            </Helmet>

            <main role="main" id="news-list">
            <h1><img src="/img/nav-news.svg" alt=""/>{headline}</h1>
            {news.map((article) => (
                <section key={article.news_id}>
                <Link to={`/news/post.html?id=${article.news_id}`}>
                    <div className="newsDate">{article.post_date}</div>
                    <div className="name">{article.zoo_name}</div>
                    <h2>{article.title}</h2>
                </Link>
                </section>
            ))}
            {/* ページ送り */}
            <ul className="newsPageNav">
                {prevUrl ? (
                    <li className="back">
                        <Link to={prevUrl}>古いページ</Link>
                    </li>
                ) : (
                    <li className="back">古いページ</li>
                )}
                {nextUrl ? (
                    <li className="next">
                        <Link to={nextUrl}>新しいページ</Link>
                    </li>
                ) : (
                    <li className="next">新しいページ</li>
                )}
            </ul>
            <Banner />
            </main>
        </HelmetProvider>
    );
};

export default NewsList;