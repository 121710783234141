//--------------------------------------------------------
// 動物園ページ　ナビゲーション　コンポーネント
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
const FunArea = ({fanClub}) => {
    if (!fanClub) {
        return null; // fan_clubに値がない場合は何も表示しない
    }

    return (
        <section id="fun">
            <h2><img src="/img/icon-fun2.png" alt=""/>もっと楽しむ</h2>
            <div className="contentsBody">
                <p className="text1">
                    イベントに参加したり動物園に貢献出来る『{fanClub}』があります。詳しくは動物園まで<a href="#toiawase">お問い合わせ</a>ください。
                </p>
            </div>
        </section>
    );
};

export default FunArea;