//--------------------------------------------------------
// フォームバリデーション関数
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
// 各フィールドのバリデーション関数
// rulesの形式: required|email|max:50
export const validateField = (value, rules, label) => {
    let errorMsg = '';

    // ルールを '|' で分割して配列に
    const ruleList = rules ? rules.split('|') : [];

    for (const rule of ruleList) {
        const [ruleName, ruleValue] = rule.split(':');

        switch (ruleName) {
        case 'required':
            if (!value) {
                errorMsg = `※${label}を入力してください`;
            }
            break;
        
        case 'requiredSelect':
            if (!value) {
                errorMsg = `※${label}を選択してください`;
            }
            break;
        
        case 'email':
            if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
            errorMsg = `※正しい${label}を入力してください`;
            }
            break;

        case 'max':
            const maxLength = parseInt(ruleValue, 10);
            if (value && value.length > maxLength) {
            errorMsg = `※${label}は${maxLength}文字以内で入力してください`;
            }
            break;

        default:
            break;
        }

        // エラーメッセージが設定されたらループを中断
        if (errorMsg) break;
    }

    return errorMsg;
};

// フォーム全体のバリデーション関数
//fieldConfigsの形式: name: { label: 'お名前', rules: 'required|max:50' }
export const validateForm = (formData, fieldConfigs) => {
    let valid = true;
    const errors = {};
    Object.keys(fieldConfigs).forEach((key) => {
        const { label, rules } = fieldConfigs[key];
        const errorMsg = validateField(formData[key], rules, label);
        
        if (errorMsg) {
          errors[key] = errorMsg;
          valid = false;
        }
    });
  
    return { valid, errors };
};