//--------------------------------------------------------
// いま人気の特集ページ　コンポーネント
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Pickup = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // 定義
    const [pickupData, setPickupData] = useState(null);
    const [error, setError] = useState(false); // エラー状態

    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    // APIからデータを取得
    useEffect(() => {
        fetch(`${apiUrl}/api/pickup`)
            .then((response) => {
                if (!response.ok) {
                    setError(true);
                }
                return response.json();
            })
            .then((data) => setPickupData(data))
            .catch(() => setError(true));
    }, [apiUrl]);

    // ウィンドウサイズを監視
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // サイズ変更で更新
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Slickの設定
    const mobileSettings = {
        arrows: false,
        dots: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        autoplay: true,
        centerMode: true,
        centerPadding: "20%", // モバイル用のパディング
    };

    // エラーハンドリングまたはデータ未取得時の処理
    if (error || !pickupData) {
        return null;
    }

    return (
        <section id="pickup">
            <h2>
                <img src="/img/icon-pickupR.svg" alt="" />
                いま人気の特集ページ
            </h2>
            <div className="sliderBox">
                {isMobile ? (
                    <Slider className="slider" {...mobileSettings}>
                        {pickupData.items.map((item, index) => (
                            <div className="sliderInner" key={index}>
                                <a href={item.link}>
                                    <img src={item.image} alt="" />
                                    <p>{item.description}</p>
                                </a>
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <div className="slider">
                        {pickupData.items.map((item, index) => (
                            <div className="sliderInner" key={index}>
                                <a href={item.link}>
                                    <img src={item.image} alt="" />
                                    <p>{item.description}</p>
                                </a>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </section>
    );
};

export default Pickup;