//--------------------------------------------------------
// 動物園ページ　ナビゲーション　コンポーネント
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
import { hasDataWithPrefix, shouldDisplayNightSafari } from '../../utils/helpers';

const Navigation = ({zooData}) => {
  const showFun = Boolean(zooData.fan_club); // fan_clubに値があれば表示
  const showAnniversary = zooData.anniversary_flag === "1"; //anniversary_flag=1なら表示

  // 夜間開園の表示判定
  const showNight = hasDataWithPrefix(zooData, 'a_ns_') || hasDataWithPrefix(zooData, 'b_ns_');

  return (
    <div id="page-nav">
      <ul>
        {showAnniversary && (
          <li>
            <a href="#oiwai">
              <img src="/img/icon-oiwai.png" alt="周年特集" width="80" height="80" /><br />
              <span>周年特集</span>
            </a>
          </li>
        )}
        <li>
        <a href="#map">
            <img src="/img/icon-map2.png" alt="アクセス/地図" width="80" height="80" /><br />
            <span>アクセス</span>
        </a>
        </li>
        {showFun && (
          <li>
            <a href="#fun">
              <img src="/img/icon-fun2.png" alt="楽しむ" width="80" height="80" /><br />
              <span>楽しむ</span>
            </a>
          </li>
        )}
        {showNight && shouldDisplayNightSafari() && (
          <li>
            <a href="#nightsafari">
              <img src="/img/icon-night2.png" alt="夜間開園" width="80" height="80" /><br />
              <span>夜間開園</span>
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Navigation;
