//--------------------------------------------------------
// 動物園ページ　基本情報　コンポーネント
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
import { convertNewlinesToBr } from '../../utils/helpers';

const BasicInfo = ({zooData}) => {
    return (
        <div className="kihon-joho">
          <dl>
            {/* 開園時間 */}
            <dt>
              <img src="/img/icon-time.svg" alt="開園時間" />
              開園時間
            </dt>
            <dd>{convertNewlinesToBr(zooData.opening_hours)}</dd>
    
            {/* 休園日 */}
            <dt>
              <img src="/img/icon-calendar.svg" alt="休園日" />
              休園日
            </dt>
            <dd>{convertNewlinesToBr(zooData.closing_days)}</dd>
    
            {/* 入園料 */}
            <dt>
              <img src="/img/icon-money.svg" alt="入園料" />
              入園料
            </dt>
            <dd>{convertNewlinesToBr(zooData.entry_fee)}</dd>
          </dl>
        </div>
      );
};

export default BasicInfo;