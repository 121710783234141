//--------------------------------------------------------
// ナビ　コンポーネント
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
import { Link } from 'react-router-dom';
import { shouldDisplayNightSafari } from '../utils/helpers';

const Navi = () => {
    return (
        <nav>
            <ul>
                <li>
                    <a href="/list.html">
                        <img src="/img/nav-zoo.svg" alt="動物園" />動物園
                    </a>
                </li>
                <li>
                    <a href="/animal/">
                        <img src="/img/nav-pickaup.svg" alt="特集" />特集
                    </a>
                </li>
                <li>
                    <Link to="/news/">
                        <img src="/img/nav-news.svg" alt="ニュース" />ニュース
                    </Link>
                </li>
                <li>
                {shouldDisplayNightSafari() ? (
                    <a href="/nightsafari.html">
                        <img src="/img/nav-night.svg" alt="夜間開園" />夜間開園
                    </a>
                ) : (
                    <a href="/animalday/">
                        <img src="/img/nav-day.svg" alt="記念日" />記念日
                    </a>
                )}
                </li>
            </ul>
        </nav>
    );
};

export default Navi;
