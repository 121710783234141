//--------------------------------------------------------
// フッター　コンポーネント
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
const Footer = () => {
    return (
        <footer>
            <p>
                動物園に行こう!!は、ホームページ制作会社の<br />
                <a href="https://www.panda-panda.com/" target="_blank" rel="noopener noreferrer">
                    パンダ工房
                </a>
                が運営・制作しています。
            </p>
            <div className="copyright">
                copyright(c) 
                <a href="https://www.panda-panda.com/" target="_blank" rel="noopener noreferrer">
                    Panda Koubou.
                </a>
            </div>
        </footer>
    );
};

export default Footer;
