//--------------------------------------------------------
// 動物園ページ　アクセス・地図　コンポーネント
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
import { convertNewlinesToBr, parseLinks } from '../../utils/helpers';

const AccessMap = ({ zooData }) => {
    if (!zooData) {
        return null; // データがない場合は何も表示しない
    }

    const {
        prefecture,
        address, 
        phone_number,
        google_map,
        access_1, 
        access_2, 
        access_3, 
        homepage_url
    } = zooData;

    return (
        <section id="map">
            <h2><img src="/img/icon-map2.png" alt=""/>アクセス・地図</h2>
            <div className="contentsBody">
                {google_map && (
                    <div id="chizu" dangerouslySetInnerHTML={{ __html: google_map }} />
                )} 
                <div id="address">{prefecture}{address}</div>

                {access_1 && (
                    <div className="access">
                        <p className="komidashi">アクセス1</p>
                        <p>{parseLinks(convertNewlinesToBr(access_1))}</p>
                    </div>
                )}

                {access_2 && (
                    <div className="access">
                        <p className="komidashi">アクセス2</p>
                        <p>{parseLinks(convertNewlinesToBr(access_2))}</p>
                    </div>
                )}

                {access_3 && (
                    <div className="access">
                        <p className="komidashi">アクセス3</p>
                        <p>{parseLinks(convertNewlinesToBr(access_3))}</p>
                    </div>
                )}

                <div id="toiawase">
                    <p className="chui">※情報が最新でない場合があります。公式サイトや電話でご確認の上、お出かけください。</p>
                    <ul>
                        <li className="tell"><a href={`tel:${phone_number}`}>TEL：{phone_number}</a></li>
                        <li><a href={homepage_url} target="_blank" rel="noopener noreferrer">公式サイトへ</a></li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default AccessMap;
