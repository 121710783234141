//--------------------------------------------------------
// トップページ
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Pickup from '../components/Pickup';
import ZooList from '../components/Top/ZooList';
import Buttons from '../components/Top/Buttons';
import Banner from '../components/Banner';

const Top = () => {
    const newsDisplayNum            = 5;
    const [news, setNews]           = useState([]); // ニュースデータ
    const [loading, setLoading]     = useState(true); // ローディング状態
    const [error, setError]         = useState(null); // エラー状態

    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    // コンポーネントがマウントされたときにAPIからデータを取得
    useEffect(() => {
        const params = {
            limit: newsDisplayNum,
        };

        axios.get(`${apiUrl}/api/news-top`, { params })  // CodeIgniter API
        .then(response => {
            const data = response.data;
            setNews(data.items);  // ニュースデータ
            setLoading(false);  // ローディング終了
        })
        .catch(err => {
            setError('ニュースの取得に失敗しました');
            setLoading(false);  // エラーが発生した場合もローディングを終了
        });
    }, [apiUrl, newsDisplayNum]);

    // ローディング中
    if (loading) {
        return <p className="loading"><img src="/img/loading.svg" alt="読み込み中" width="200" height="200"/></p>;
    }

    // トップページ表示
    return (
        <HelmetProvider>
            <Helmet>
                <meta name="description" content="日本国内にある動物園・水族館・観光牧場の開園時間、料金、アクセス、地図などの情報を掲載してます。" />
                <link rel="stylesheet" href="/css/top.css?v=20241030" />
            </Helmet>
            <main role="main">
                <p className="leadText"><img src="/img/top-lead.png" alt="日本の動物園紹介サイトです。ピクニック、遠足、デートで動物園に行こう!!"/></p>
                {/* エラーがなく、ニュースがある場合にのみ表示 */}
                {!error && news.length > 0 && (
                <section id="news">
                    <h2><span><img src="/img/icon-newsW.svg" alt="動物園ニュース" />動物園ニュース</span></h2>
                    <div className="newsBody">
                    {news.map((article) => (
                        <Link to={`/news/post.html?id=${article.news_id}`} className="newsInner" key={article.news_id}>
                            <div className="photo">
                                {/*サムネイル*/}
                                <img
                                src={article.thumbnail ? article.thumbnail : "/img/noimage.png"}
                                alt={article.title}
                                loading="lazy"
                                />
                            </div>
                            <div className="text">
                                <p className="newsDay">{article.post_date}</p>
                                <p className="zooName">{article.zoo_name}</p>
                                <p>{article.title}</p>
                            </div>
                        </Link>
                    ))}
                    </div>
                    <div className="btn">
                        <Link to="/news/">ニュース一覧へ &gt;&gt;</Link>
                    </div>
                </section>
                )}
                <Pickup />
                <ZooList />
                <Buttons />
                <Banner />
            </main>
        </HelmetProvider>
    );
};

export default Top;