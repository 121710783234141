//--------------------------------------------------------
// お問い合わせフォーム
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
import { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { validateField, validateForm } from '../utils/validation';
import Banner from '../components/Banner';

const ContactForm = () => {
    
    //フォームデータ格納配列
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        inquiryType: '',
        content: '',
        source: '',
    });

    const [errors, setErrors]               = useState({}); //エラーを格納する
    const [success, setSuccess]             = useState({}); //エラーがない場合
    const [csrfToken, setCsrfToken]         = useState({ name: '', value: '' }); //CSRFトークンを保存
    const [viewState, setViewState]         = useState('input'); //画面ステータス
    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    // フォームが表示されたときにCSRFトークンを取得
    useEffect(() => {
        fetch(`${apiUrl}/api/contact/get-csrf-token`)
        .then((response) => response.json())
        .then((data) => {
            setCsrfToken({ name: data.csrf_token_name, value: data.csrf_hash });
        })
        .catch((error) => {
            // console.error("CSRFトークンの取得に失敗しました:", error);
        });
    }, [apiUrl]);

    // ラベルとバリデーションルール
    const fieldConfigs = {
        name: { label: 'お名前', rules: 'required|max:50' },
        company: { label: '会社名', rules: '' },
        email: { label: 'メールアドレス', rules: 'required|email' },
        inquiryType: { label: 'お問い合わせの種類', rules: 'requiredSelect' },
        content: { label: 'お問い合わせの内容', rules: 'required|max:500' },
        source: { label: '何をご覧になられお知りになりましたか？', rules: '' },
    };

    // フィールドがフォーカスを外れた時にバリデーションを実行
    const handleBlur = (e) => {
        const { name, value } = e.target;
        validateFieldAndSetError(name, value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        // ラジオボタンやセレクトボックスの場合は、変更時にバリデーション
        if (e.target.type === 'radio' || e.target.tagName === 'SELECT') {
            validateFieldAndSetError(name, value);
        }
    };

    const validateFieldAndSetError = (name, value) => {
        const errorMsg = validateField(value, fieldConfigs[name].rules, fieldConfigs[name].label);
        setErrors((prevErrors) => ({ ...prevErrors, [name]: errorMsg }));
        // バリデーション結果に基づいてsuccessステートを更新
        setSuccess((prevSuccess) => ({
            ...prevSuccess,
            [name]: !errorMsg // エラーメッセージがない場合はtrue（バリデーション成功）
        }));
    };

    // フォーム確認
    const handleConfirm = (e) => {
        e.preventDefault();

        // validateForm関数で全体バリデーションを実行
        const { valid, errors } = validateForm(formData, fieldConfigs);
        setErrors(errors);
        if (valid) {
            setViewState('confirm'); // バリデーションエラーがなければ確認画面に遷移
        }
        else {
            alert("入力内容に誤りがあります。");
        }

        //ページスクロールをトップに
        window.scrollTo(0, 0);
    };

    //フォーム最終送信
    const handleFinalSubmit = async (e) => {
        e.preventDefault();

        // CSRFトークンを含めてフォームデータを送信
        const payload = {
          ...formData,
          [csrfToken.name]: csrfToken.value,
        };
    
        const formDataEncoded = new URLSearchParams(payload).toString();
        
        try {
          const response = await fetch(`${apiUrl}/api/contact/submit`, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: formDataEncoded, // エンコードされたデータを送信
          });
    
          if (response.ok) {
            setViewState('submitted'); // 送信成功で完了画面を表示
          } else {
            alert("送信に失敗しました。もう一度お試しください。");
          }
        } catch (error) {
          //console.error("送信エラー:", error);
          alert("送信エラーが発生しました。もう一度お試しください。");
        }
        
    };

    return (
        <HelmetProvider key={viewState}>
            <Helmet>
                <title>お問い合わせ〜動物園に行こう!!</title>
                <link rel="stylesheet" href="/css/contact.css?v=20241108" />
            </Helmet>

            <main>
                <h1>お問い合わせ</h1>
                <div id="pageNav">
                    <ul>
                        <li id={viewState === 'input' ? "sentaku" : ""}>1. 項目の入力</li>
                        <li id={viewState === 'confirm' ? "sentaku" : ""}>2. 内容の確認</li>
                        <li id={viewState === 'submitted' ? "sentaku" : ""}>3. 送信完了</li>
                    </ul>
                </div>

                {(viewState === 'input' || viewState === 'confirm') && (
                    <div className="basic-text">
                        {viewState === 'confirm'
                            ? "以下の内容でよろしければ「送信する」ボタンをクリックしてください。"
                            : "以下のフォームに、お問い合わせ内容を入力し送信してください。"}
                    </div>
                )}
                {viewState === 'submitted' && (
                    <div className="basic-text2">
                        お問い合わせを受け付けました。
                    </div>
                )}

                {viewState === 'input' && (
                    // 入力画面
                    <form onSubmit={handleConfirm} id="form_box">
                        <dl id="contactBox">
                            <dt>{fieldConfigs.name.label} <span className="hissu">必須</span></dt>
                            <dd>
                                <p className={`formstyle ${success.name ? 'check' : ''} ${errors.name ? 'err' : ''}`}>
                                    <input
                                    id="name"
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="例：山田　太郎"
                                    />
                                </p>
                                {errors.name && <p className="error">{errors.name}</p>}
                            </dd>

                            <dt>{fieldConfigs.company.label} <span className="nini">任意</span></dt>
                            <dd>
                                <p className={`formstyle ${success.company ? 'check' : ''} ${errors.company ? 'err' : ''}`}>
                                    <input
                                    id="company"
                                    type="text"
                                    name="company"
                                    value={formData.company}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="例：パンダ株式会社"
                                    />
                                </p>
                                {errors.company && <p className="error">{errors.company}</p>}
                            </dd>

                            <dt>{fieldConfigs.email.label} <span className="hissu">必須</span></dt>
                            <dd>
                                <p className={`formstyle ${success.email ? 'check' : ''} ${errors.email ? 'err' : ''}`}>
                                    <input
                                    id="email"
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="例：panda@panda-panda.com"
                                    />
                                </p>
                                {errors.email && <p className="error">{errors.email}</p>}
                            </dd>

                            <dt>{fieldConfigs.inquiryType.label} <span className="hissu">必須</span></dt>
                            <dd>
                                <div className={`radio-check-list ${success.inquiryType ? 'check' : ''} ${errors.inquiryType ? 'err' : ''}`}>
                                    <fieldset data-role="controlgroup" id="syurui_group">
                                        <ul>
                                            <li>
                                                <input
                                                id="syurui_1"
                                                type="radio"
                                                name="inquiryType"
                                                value="動物園の情報提供"
                                                checked={formData.inquiryType === "動物園の情報提供"}
                                                onChange={handleChange}
                                                />
                                                <label htmlFor="syurui_1">動物園の情報提供</label>
                                            </li>
                                            <li>
                                                <input
                                                id="syurui_2"
                                                type="radio"
                                                name="inquiryType"
                                                value="パンダの情報提供"
                                                checked={formData.inquiryType === "パンダの情報提供"}
                                                onChange={handleChange}
                                                />
                                                <label htmlFor="syurui_2">パンダの情報提供</label>
                                            </li>
                                            <li>
                                                <input
                                                id="syurui_3"
                                                type="radio"
                                                name="inquiryType"
                                                value="レッサーパンダの情報提供"
                                                checked={formData.inquiryType === "レッサーパンダの情報提供"}
                                                onChange={handleChange}
                                                />
                                                <label htmlFor="syurui_3">レッサーパンダの情報提供</label>
                                            </li>
                                            <li>
                                                <input
                                                id="syurui_4"
                                                type="radio"
                                                name="inquiryType"
                                                value="掲載内容の間違いの連絡"
                                                checked={formData.inquiryType === "掲載内容の間違いの連絡"}
                                                onChange={handleChange}
                                                />
                                                <label htmlFor="syurui_4">掲載内容の間違いの連絡</label>
                                            </li>
                                            <li>
                                                <input
                                                id="syurui_5"
                                                type="radio"
                                                name="inquiryType"
                                                value="その他"
                                                checked={formData.inquiryType === "その他"}
                                                onChange={handleChange}
                                                />
                                                <label htmlFor="syurui_5">その他</label>
                                            </li>
                                        </ul>
                                    </fieldset>
                                </div>
                                {errors.inquiryType && <p className="error">{errors.inquiryType}</p>}
                            </dd>

                            <dt>{fieldConfigs.content.label} <span className="hissu">必須</span></dt>
                            <dd>
                                <p className={`formstyle ${success.content ? 'check' : ''} ${errors.content ? 'err' : ''}`}>
                                    <textarea
                                    id="naiyou"
                                    cols="40"
                                    rows="8"
                                    name="content"
                                    value={formData.content}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="お問い合わせ内容を入力してください"
                                    ></textarea>
                                </p>
                                {errors.content && <p className="error">{errors.content}</p>}
                            </dd>

                            <dt>{fieldConfigs.source.label} <span className="nini">任意</span></dt>
                            <dd>
                                <div className={`formstyle ${success.source ? 'check' : ''} ${errors.source ? 'err' : ''}`}>
                                    <select
                                    id="what"
                                    name="source"
                                    value={formData.source}
                                    onChange={handleChange}
                                    >
                                    <option value="">選択してください</option>
                                    <option value="Google検索">Google検索</option>
                                    <option value="Yahoo検索">Yahoo検索</option>
                                    <option value="その他検索">その他検索</option>
                                    <option value="クチコミ">クチコミ</option>
                                    <option value="TV・ラジオ">TV・ラジオ</option>
                                    <option value="雑誌・新聞">雑誌・新聞</option>
                                    <option value="その他">その他</option>
                                    </select>
                                </div>
                                {errors.source && <p className="error">{errors.source}</p>}
                            </dd>
                        </dl>

                        <div id="linkBox">
                            <button type="submit" id="submit_btn">確認画面に進む</button>
                        </div>
                    </form>
                )}

                {viewState === 'confirm' && (
                    // 確認画面
                    <form onSubmit={handleFinalSubmit} id="form_box">
                        <dl id="contactBox" className="kakunin">
                            <dt>お名前</dt>
                            <dd>{formData.name}</dd>
                            <dt>会社名</dt>
                            <dd>{formData.company}</dd>
                            <dt>メールアドレス</dt>
                            <dd>{formData.email}</dd>
                            <dt>お問い合わせの種類</dt>
                            <dd>{formData.inquiryType}</dd>
                            <dt>お問い合わせの内容</dt>
                            <dd>{formData.content}</dd>
                            <dt>何をご覧になられてお知りになりましたか？</dt>
                            <dd>{formData.source}</dd>
                        </dl>
                        <div id="linkBox">
                            <button type="button" className="back" onClick={() => {setViewState('input'); window.scrollTo(0, 0);}}>戻る</button>
                            <button type="submit">送信する</button>
                        </div>
                    </form>    
                )}

                {viewState === 'submitted' && (
                    // 完了画面
                    <>
                    <p>お問合せ頂いた内容を確認後、担当者よりお返事させていただきます。<br />なお、数日経っても連絡がない場合は送信が完了していない可能性がありますので、大変お手数ですがもう一度送信してください。</p>
                    <div id="linkBox"><Link to="/">ホームへ戻る</Link></div>
                    </>
                )}
            <Banner />
            </main>
        </HelmetProvider>
    );
}

export default ContactForm;