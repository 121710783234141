//--------------------------------------------------------
// 動物園ページ　ニュースエリア　コンポーネント
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import useReloadScripts from '../../hooks/useReloadScripts';

const NewsArea = ({ zooId }) => {
    const [newsData, setNewsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        if (zooId) {
            axios.get(`${apiUrl}/api/news-zoo-latest/${zooId}`)
                .then((response) => {
                    if (response.data.items) {
                        setNewsData(response.data.items);
                    } else {
                        setError(true);
                    }
                    setLoading(false);
                })
                .catch(() => {
                    setError(true);
                    setLoading(false);
                });
        }
    }, [zooId, apiUrl]);

    // newsData の変更時にスクリプトをリロード
    useReloadScripts(newsData, 'contentsBody');
    
    if (loading) {
        return <p className="loading"><img src="/img/loading.svg" alt="読み込み中" width="200" height="200"/></p>;
    }

    if (loading || error || newsData.length === 0) {
        return null;
    }

    return (
        <section id="midokoro">
            <h2><img src="/img/nav-news2.svg" alt=""/>ニュース</h2>
            <div className="contentsBody">
            {newsData.map((news, index) => (
                    <div key={index} className="textBox1">
                        <p className="textTitle">{news.title}</p>
                        <div
                            className="text1"
                            dangerouslySetInnerHTML={{ __html: news.body.replace(/\r\n/g, '<br>') }}
                        ></div>
                        <p className="text2">{news.post_date}</p>
                    </div>
                ))}
                <p className="ichiranBtn">
                    <Link to={`/news/?zoo_id=${zooId}`}>
                        {newsData[0]?.zoo_name}のニュース一覧
                    </Link>
                </p>
            </div>
        </section>
    );
};

export default NewsArea;
