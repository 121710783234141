//--------------------------------------------------------
// ヘッダー　コンポーネント
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <header>
            <div>
                <Link to="/">
                    <img src="/img/head-logo.png" alt="動物園に行こう！！" />
                </Link>
            </div>
        </header>
    );
};

export default Header;
