//--------------------------------------------------------
// 動物園ページ　臨時情報　コンポーネント
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
const ExtraInfo = ({extraInfo}) => {
    if (!extraInfo) {
        return null; // 臨時情報がない場合は何も表示しない
    }

    return (
        <div id="extra_info">
            <p>{extraInfo}</p>
        </div>
    );
};

export default ExtraInfo;