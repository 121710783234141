//--------------------------------------------------------
// 埋め込みスクリプトの処理
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
import { useEffect } from 'react';

function useReloadScripts(dependency, className = 'newsContent') {
    useEffect(() => {
        if (dependency) {
            const scripts = document.getElementsByClassName(className)[0]?.querySelectorAll('script');
            if (scripts) {
                scripts.forEach(script => {
                    const parentNode = script.parentNode;
                    const alternativeNode = document.createElement('script');
                    alternativeNode.src = script.src;
                    parentNode.replaceChild(alternativeNode, script);
                });
            }
        }
    }, [dependency, className]);
}

export default useReloadScripts;
