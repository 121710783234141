//--------------------------------------------------------
// 近くの動物園　コンポーネント
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
const NearZoos = ({nearbyZoos}) => {
    if (!nearbyZoos || nearbyZoos.length === 0) {
      return null;
    }
  
    return (
      <section id="chikaku">
        <h2>
          <img src="/img/nav-zoo2.svg" alt=""/>近くの動物園
        </h2>
        <div className="contentsBody">
          <ul>
            {nearbyZoos.map((zoo) => (
              <li key={zoo.zoo_id} className="zooList">
                <a href={`/zoo/${zoo.zoo_id}.html`}>{zoo.zoo_name}</a>
              </li>
            ))}
          </ul>
        </div>
      </section>
    );
};
export default NearZoos;
  