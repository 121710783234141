//--------------------------------------------------------
// 動物園ページ　見どころ　コンポーネント
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
import { convertNewlinesToBr } from '../../utils/helpers';

const Highlights = ({highlights}) => {
    if (!highlights) {
        return null; // 見どころがない場合は何も表示しない
    }

    return (
        <div id="intro">
            <p className="text1">
            {convertNewlinesToBr(highlights)}
            </p>
        </div>
    );
};

export default Highlights;