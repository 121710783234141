//--------------------------------------------------------
// ニュース　詳細
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSearchParams, Link } from 'react-router-dom';
import useReloadScripts from '../hooks/useReloadScripts';
import Banner from '../components/Banner';

const NewsArticle = () => {
    const [newsDetail, setNewsDetail]   = useState(null);  // ニュース記事データ
    const [loading, setLoading]         = useState(true);  // ローディング状態
    const [error, setError]             = useState(false);  // エラー状態

    // クエリパラメータ（id）を取得
    const [searchParams]    = useSearchParams();
    const newsId            = searchParams.get('id');
    const apiUrl            = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        if (newsId) {
            // APIから特定のニュース記事を取得
            axios.get(`${apiUrl}/api/news-article?id=${newsId}`)
            .then(response => {
                if (response.data) {
                    setNewsDetail(response.data);
                } else {
                    setError(true); // 記事が見つからない場合もエラー扱い
                }
                setLoading(false);
            })
            .catch(err => {
                setError(true);
                setLoading(false);
            });
        } else {
            setError(true);
            setLoading(false);
        }
    }, [newsId, apiUrl]);

    // エラーが発生した場合に404ページにリダイレクト
    useEffect(() => {
        if (error) {
            window.location.href = '/404.html';
        }
    }, [error]);

    // useEffectで埋め込みスクリプトタグを再実行
    useReloadScripts(newsDetail);

    // ローディング中
    if (loading) {
        return null;
    }

    if (!newsDetail) {
        return null;
    }

    // bodyの中の \r\n を <br> に変換
    const formattedBody = newsDetail.body.replace(/\r\n/g, '<br>');

    return (
        <HelmetProvider>
            <Helmet>
                <title>{newsDetail.title} │ ニュース〜動物園に行こう!!</title>
                <meta name="description" content="動物園のニュースを紹介しています。" />
                <link rel="stylesheet" href="/css/news.css?v=20241030" />
            </Helmet>

            <main role="main" id="news-article">
                <h1><img src="/img/nav-news.svg" alt="ニュース"/>ニュース</h1>
                <section className="newsPost">
                    <div className="newsDate">{newsDetail.post_date}</div>
                    <h2>{newsDetail.title}</h2>
                    <div className="newsContent" dangerouslySetInnerHTML={{ __html: formattedBody }}></div>
                    <div className="newsLink"><a href={newsDetail.zoo_url}>{newsDetail.zoo_name}</a></div>		
                </section>
                {/* ページ送り */}
                <ul className="newsPageNav">
                    {newsDetail.prev_url ? (
                        <li className="back">
                            <Link to={newsDetail.prev_url}>古いページ</Link>
                        </li>
                    ) : (
                        <li className="back">古いページ</li>
                    )}
                    {newsDetail.next_url ? (
                        <li className="next">
                            <Link to={newsDetail.next_url}>新しいページ</Link>
                        </li>
                    ) : (
                        <li className="next">新しいページ</li>
                    )}
                </ul>
                <div className="newsHome">
                    <Link to="/news/">ニュース一覧へ戻る</Link>
                </div>
                <Banner />
            </main>
        </HelmetProvider>
    );
};

export default NewsArticle;