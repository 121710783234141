//--------------------------------------------------------
// トップ/ボタンエリア　コンポーネント
// Create 2024/11 PANDA DOBASHI
//--------------------------------------------------------
import { Link } from 'react-router-dom';

const Buttons = () => {
    return (
        <div className="btnArea">
            <div className="btnzoo"><a href="/info/caution.html"><img src="/img/btn-zoo.png" alt="動物園で守ること"/></a></div>
            <div className="btnOmake"><a href="/omake/omake.html">おまけページ<span>動物園に行こうオリジナルカレンダーと壁紙がダウンロードできます</span></a></div>
            <div className="btnAbout"><a href="/setsumei.html"><img src="/img/btn-about.png" alt=""/>動物園に行こう!!について</a></div>
            <div className="btnContact"><Link to="/contact/"><img src="/img/btn-contact.png" alt=""/>掲載情報の訂正<br />お問い合わせ</Link></div>
        </div>
    );
};

export default Buttons;